export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
      noCheck: true,
    },
  },
  {
    path: '/findPassword',
    name: 'findPassword',
    component: () => import('@/views/login/ForgetPassword.vue'),
    meta: {
      title: '找回密码',
      noCheck: true,
    },
  },
]
