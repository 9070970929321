import Http from '@/libs/http/index'
const basePath = '/applet-admin/collect-applet-management/'
import adminAuthDefault from './admin-auth-default.json'
export default {
  getPermission(): Promise<WebResponse<PermissionModel[]>> {
    return new Promise((resolve: any) => {
      resolve({
        code: 200,
        data: adminAuthDefault,
        msg: '',
      })
    })
  },
  getAdminMenus() {
    return Http({
      method: 'GET',
      url: basePath + 'management/adminMenu/auth/get',
    })
  },
}
interface PermissionModel {
  id: number
  label: string
  front: string
  childs?: Array<PermissionModel>
}
