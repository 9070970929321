// import Layout from '@/layout/Index.vue'
import Layout from '@/layout/Main.vue'
export default [
  {
    path: '/data',
    name: 'data',
    component: Layout,
    sign: 'data_management',
    meta: {
      title: '数据管理',
    },
    redirect: '/data/dept',
    children: [
      {
        path: 'dept',
        name: 'dept',
        sign: 'dept_management',
        meta: {
          title: '科室管理',
        },
        component: () => import('@/views/department/index.vue'),
      },
    ],
  },
]
