// import Layout from '@/layout/Index.vue'
import Layout from '@/layout/Main.vue'
export default [
  {
    path: '/admin',
    name: 'admin',
    component: Layout,
    sign: 'back_user_center',
    meta: {
      // title: '用户中心',
    },
    redirect: '/admin/user',
    children: [
      {
        path: 'user',
        name: 'adminUser',
        sign: 'back_user_manage',
        meta: {
          // title: '用户管理',
        },
        component: () => import('@/views/admin/user/index.vue'),
      },
      {
        path: 'role',
        name: 'roleManage',
        sign: 'back_role_manage',
        meta: {
          // title: '角色管理',
        },
        component: () => import('@/views/admin/role/index.vue'),
      },
      {
        path: 'auth',
        name: 'authManage',
        sign: 'back_auth_manage',
        meta: {
          // title: '权限管理',
        },
        component: () => import('@/views/admin/auth/index.vue'),
      },
      {
        path: 'auth/authResource/:authId',
        name: '权限资源配置',
        sign: 'back_auth_menu_manage',
        hidden: true,
        meta: { currentIndex: '/user/auth' },
        component: () => import('@/views/admin/auth/menuAuthResource.vue'),
      },
    ],
  },
]
