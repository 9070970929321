/* eslint-disable @typescript-eslint/no-unused-vars */
import { useResizeObserver } from '@vueuse/core'
/**
 * How to use
 * <el-table height="100px" v-el-height-adaptive-table="{bottomOffset: 30}">...</el-table>
 * el-table height is must be set
 * bottomOffset: 30(default)   // The height of the table from the bottom of the page.
 */

const doResize = (el, binding, vnode) => {
  //TODO:vue3.0 vnode中没有了componentInstantce属性了
  // const { componentInstance: $table } = vnode
  const tableHeight = el.offsetHeight
  const { value } = binding

  if (!tableHeight) {
    throw new Error(`el-$table must set the height. Such as height='100px'`)
  }
  const bottomOffset = (value && value.bottomOffset) || 30

  // if (!$table) return
  const height =
    window.innerHeight - el.getBoundingClientRect().top - bottomOffset
  if (!tableHeight || height === tableHeight) return
  el.style.height = height + 'px'
  // $table.layout.setHeight(height)
  // $table.doLayout()
}

export default {
  beforeMount(el, binding, vnode) {
    // parameter 1 is must be "Element" type
    el.resizeListener = useResizeObserver(window.document.body, entries => {
      doResize(el, binding, vnode)
    })
  },
  updated(el, binding, vnode, oldVnode) {
    // console.log('===', vnode === oldVnode);
    doResize(el, binding, vnode)
  },
  mounted(el, binding, vnode) {
    doResize(el, binding, vnode)
  },
  unmounted(el) {
    el.resizeListener?.stop && el.resizeListener?.stop()
  },
}
