/*
 * @Author: Chance Lau
 * @Date: 2023-04-27 19:48:45
 * @LastEditors: Chance Lau
 * @LastEditTime: 2023-12-27 10:37:17
 * @Description: file content
 */
import axios, { AxiosRequestConfig } from 'axios'
import router from '@/router/index'
import { httpStatus } from './http-status'
import { useUserStore } from '@/store/modules/user'
import { AnyColumn } from 'element-plus/es/components/table-v2/src/common'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.common['Remote-Host'] =
  import.meta.env.VITE_APP_BASE_DOMAIN || window.location.hostname
const http = axios.create({
  baseURL: `${import.meta.env.VITE_APP_BASE_API}`,
  timeout: 60 * 1000,
})

function filterEmpty(obj: AnyColumn) {
  if (!obj) return
  Object.keys(obj).forEach((key: any) => {
    if (obj[key] === '') {
      obj[key] = null
    }
  })
}
// 请求拦截器
http.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    if (config.headers) {
      const userStore = useUserStore()
      config.headers.Authorization = userStore.getToken
    }
    filterEmpty(config.data)
    return config
  },
  error => {
    console.log(error.data.error.message)
    return Promise.reject(error.data.error.message)
  }
)

http.interceptors.response.use(
  response => {
    const status = response.status
    const resData = response.data
    if (status === 200) {
      //通信异常
      if (resData.code === 210) {
        // token失效
        const userStore = useUserStore()
        userStore.removeToken()
        router.push({ path: '/login' })
        return
      } else if (resData?.code !== 200) {
        const { msg, message, ...resResult } = resData
        return Promise.reject({
          ...resResult,
          message: msg || message,
        })
      } else {
        return resData
      }
    } else if (httpExceptionHandler(response)) {
      //已处理，暂时不返回数据，不响应
    } else {
      console.error('接口返回错误：', response)
      return Promise.reject(response)
    }
  },
  function (error) {
    // http 错误码处理    console 待换成集成UI的弹出提示
    console.error('axios接口返回错误：', error)
    return Promise.reject(error)
  }
)
const httpExceptionHandler = (response: any): boolean => {
  const msg = httpStatus[response.status]
  if (msg) {
    console.error(response)
    // TODO: 处理断网的情况
    // eg:请求超时或断网时，更新state的network状态
    // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
    // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
    // store.commit('changeNetwork', false)
    return true //已处理
  }
  return false //未处理
}
export default http
