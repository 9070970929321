/*
 * @Author: Chance Lau
 * @Date: 2023-05-08 13:02:56
 * @LastEditors: Chance Lau
 * @LastEditTime: 2024-03-18 16:50:09
 * @Description: file content
 */
// import Layout from '@/layout/Index.vue'
import Layout from '@/layout/Main.vue'
export default [
  {
    path: '/system',
    name: 'system',
    component: Layout,
    sign: 'system_management',
    meta: {
      title: '系统管理',
    },
    redirect: '/system/notice',
    children: [
      {
        path: 'notice',
        name: 'notice',
        sign: 'notice_management',
        meta: {
          title: '公告管理',
        },
        component: () => import('@/views/notice/List.vue'),
      },
      {
        path: 'noticeAdd',
        name: 'noticeAdd',
        sign: 'notice_management_add',
        hidden: true,
        meta: {
          title: '公告新增',
        },
        component: () => import('@/views/notice/Edit.vue'),
      },
      {
        path: 'noticeEdit',
        name: 'noticeEdit',
        sign: 'notice_management_edit',
        hidden: true,
        meta: {
          title: '公告编辑',
        },
        component: () => import('@/views/notice/Edit.vue'),
      },
      {
        path: 'bannerManager',
        name: 'bannerManager',
        sign: 'banner_img',
        meta: {
          title: '轮播图管理',
        },
        component: () => import('@/views/notice/bannerList.vue'),
      },

      {
        path: 'bannerCreate',
        name: 'bannerCreate',
        hidden: true,
        meta: {
          title: '轮播图新增/编辑',
        },
        component: () => import('@/views/notice/bannerCreate.vue'),
      },
    ],
  },
]
