<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script lang="ts" setup>
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { useUserStore } from '@/store/modules/user'
const locale = ref(zhCn)
const userStore = useUserStore()
watchEffect(() => {
  const token = userStore.getToken
  const userInfo = userStore.getUserInfo
  //已经登录，但没有用户信息时，重新请求
  if (token && !userInfo) {
    userStore.initUserInfo()
  }
})
</script>
<style lang="scss" scoped></style>
