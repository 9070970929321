// import Layout from '@/layout/Index.vue'
import Layout from '@/layout/Main.vue'
export default [
  {
    path: '/user',
    name: 'user',
    component: Layout,
    sign: 'front_user_center',
    redirect: '/user/list',
    meta: {
      title: '信息采集用户管理',
    },
    children: [
      {
        // path: 'list',
        path: 'list/:page?',
        name: 'userList',
        component: () => import('@/views/user/index.vue'),
        sign: 'front_user_manage',
        meta: {
          title: '用户列表',
        },
      },
      {
        // path: 'unAuthList',
        path: 'unAuthList/:page?',
        name: 'unAuthList',
        component: () => import('@/views/user/index.vue'),
        sign: 'unauth_user',
        meta: {
          title: '待认证用户列表',
        },
      },
      {
        path: 'freezeUser',
        name: 'freezeUser',
        component: () => import('@/views/user/FreezeUserList.vue'),
        sign: 'freeze_user',
        meta: {
          title: '冻结用户',
        },
      },
      {
        // path: 'detail/:type?',
        path: 'detail/:type?/:page?',
        name: 'userDetail',
        component: () => import('@/views/user/Detail.vue'),
        meta: {
          title: '用户详情',
          hidden: true,
        },
      },
      {
        path: 'userImport',
        name: 'userImport',
        component: () => import('@/views/user/import/List.vue'),
        sign: 'front_user_import',
        meta: {
          title: '未注册用户导入',
        },
      },
      {
        path: 'userTagTime',
        name: 'userTagTime',
        component: () => import('@/views/user/UserTagTimeList.vue'),
        sign: 'front_user_tag_time',
        meta: {
          title: '用户标签时间查询',
        },
      },
      {
        path: 'userImportImage',
        name: 'userImportImage',
        component: () => import('@/views/user/import/BatchImportImage.vue'),
        meta: {
          title: '批量导入医生图片',
          hidden: true,
        },
      },
      {
        path: 'userImportRecord',
        name: 'userImportRecord',
        component: () =>
          import('@/views/user/import/BatchImportImageRecord.vue'),
        meta: {
          title: '批量导入医生图片',
          hidden: true,
        },
      },
      {
        path: 'dataValidation',
        name: 'dataValidation',
        component: () => import('@/views/user/DataValidation.vue'),
        sign: 'data_vilidation',
        meta: {
          title: '数据验证',
        },
      },
      {
        path: 'dataPostback',
        name: 'dataPostback',
        component: () => import('@/views/user/DataPostback.vue'),
        sign: 'data_postback',
        meta: {
          title: '数据回传',
        },
      },
      {
        path: 'userDetailEmit',
        name: 'userDetailEmit',
        component: () => import('@/views/user/EmitInfo.vue'),
        meta: {
          title: '编辑信息',
        },
      },
      {
        path: 'blackList',
        name: 'blackList',
        sign: 'black_user',
        component: () => import('@/views/user/BlackList.vue'),
        meta: {
          title: '黑名单管理',
        },
      },
    ],
  },
]
