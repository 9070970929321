<template>
  <div class="breadcrumb">
    <el-breadcrumb class="app-levelbar" separator="/">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <router-link
          v-if="item.redirect === 'noredirect' || index == levelList.length - 1"
          to=""
          class="no-redirect"
          >{{ item.meta?.title }}</router-link
        >
        <router-link v-else :to="item.redirect || item.path">{{
          item.meta?.title
        }}</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script lang="ts" setup>
import { usePermissionStore } from '@/store/modules/permission'
const permissionStore = usePermissionStore()
const levelList = ref([])

const route = useRoute()

const getBreadcrumb = () => {
  let matched: any = route.matched
  const routes = permissionStore.getRoutes
  const findTitleFn = (routeArr: any, match: any): any => {
    let title = null
    for (let i = 0; i < routeArr.length; i++) {
      title =
        routeArr[i].name === match.name || routeArr[i].path === match.path
          ? routeArr[i].meta?.title
          : null
      if (title) {
        return title
      }
      if (routeArr[i]?.children) {
        title = findTitleFn(routeArr[i]?.children, match)
        if (title) {
          return title
        }
      }
    }
    return title
  }
  matched?.forEach((m: any) => {
    if (!m.meta.title) {
      m.meta.title = findTitleFn(routes, m)
    }
  })
  levelList.value = matched
}

onMounted(() => {
  getBreadcrumb()
})

watch(
  () => route.name,
  () => {
    getBreadcrumb()
  }
)
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding-left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}
.app-levelbar.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
