// import Layout from '@/layout/Index.vue'
import Layout from '@/layout/Main.vue'
export default [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/dashboard',
    sign: 'collect_home',
    meta: { title: '首页' },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/home/index.vue'),
        meta: { title: 'Dashboard' },
      },
    ],
  },
]
