import http from '@/libs/http/index'
const basePath = '/applet-admin/collect-applet-management/'
export default {
  login(params: { username: string; password: string }) {
    return http({
      method: 'POST',
      url: basePath + 'management/adminUser/login',
      data: params,
    })
  },
  logout() {
    return http({
      method: 'GET',
      url: basePath + 'management/adminUser/logout',
    })
  },
  adminUserExist(username: string) {
    return http({
      method: 'POST',
      url: basePath + 'management/adminUser/exist',
      data: {
        username: username,
      },
    })
  },
  getUserInfo() {
    return http({
      method: 'GET',
      url: basePath + 'management/adminUser/info',
    })
  },
  forgetPwd(params: {
    username: string
    password: string
    code: string
    phone?: string
  }) {
    return http({
      method: 'POST',
      url: basePath + 'management/adminUser/password/forget',
      data: params,
    })
  },
  sendSmsByUsername(username: string) {
    return http({
      method: 'POST',
      url: basePath + 'management/adminUser/send-code-by-name',
      data: {
        username: username,
      },
    })
  },
  forgetPwdSmsValidate(params: { username: string; code: string }) {
    return http({
      method: 'POST',
      url: basePath + 'management/adminUser/password/forget/validate',
      data: params,
    })
  },
}
