// import Layout from '@/layout/Index.vue'
import Layout from '@/layout/Main.vue'
export default [
  {
    path: '/analysis',
    name: 'analysis',
    component: Layout,
    sign: 'miniApp_analysis',
    redirect: '/analysis/loginAnalysis',
    meta: {
      title: '小程序分析',
    },
    children: [
      {
        path: 'loginAnalysis',
        name: 'loginAnalysis',
        sign: 'user_login_analysis',
        component: () => import('@/views/analysis/loginAnalysis.vue'),
        meta: {
          title: '用户登录分析',
        },
      },
      {
        path: 'doctorData',
        name: 'doctorData',
        sign: 'real_doctor_data',
        component: () => import('@/views/analysis/doctorData.vue'),
        meta: {
          title: '真实医生数据',
        },
      },
      {
        path: 'WEAnalysis',
        name: 'WEAnalysis',
        sign: 'we_analysis',
        component: () => import('@/views/analysis/WEAnalysis.vue'),
        meta: {
          title: 'WE分析',
        },
      },
    ],
  },
]
